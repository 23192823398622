import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Drone(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./models/quadrocopter_drone_-_animated/scene-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
// console.log(Object.keys(actions.Scene), "drone actions");
    // actions.Object_0.timeScale = 1
    actions.Scene.play();
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="dafbe2cefa5a4c419be0da8350b4e188fbx" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Empty" position={[0.09, 70.44, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={34.14} />
                <group name="Empty006" position={[-79.16, 65.04, -77.3]} scale={100} />
                <group name="Empty007" position={[79.35, 65.04, -77.3]} scale={100} />
                <group name="Empty008" position={[79.35, 65.04, 77.3]} scale={100} />
                <group name="Empty009" position={[-79.16, 65.04, 77.3]} scale={100} />
                <group name="Cube013" position={[0.09, 67.93, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube013_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube013_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube014" position={[0.01, 62.87, 34.06]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Cube014_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube014_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane007" position={[-32.78, 64.23, 33.07]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane007_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane007_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere007" position={[0.09, 71.77, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere007_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere007_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder019" position={[41.51, 64.91, 17.04]} rotation={[-Math.PI / 2, 0.83, 0]} scale={100}>
                  <mesh name="Cylinder019_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder019_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder022" position={[-16.44, 65.91, 41.23]} rotation={[-0.81, 0, 0]} scale={100}>
                  <mesh name="Cylinder022_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder022_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube025" position={[34.15, 62.87, 0.08]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={100}>
                  <mesh name="Cube025_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube025_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube012" position={[0.01, 62.87, -34.06]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Cube012_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube012_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane018" position={[32.97, 64.23, 33.07]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane018_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane018_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane019" position={[-32.78, 64.23, -33.07]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane019_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane019_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder050" position={[41.51, 64.91, -16.88]} rotation={[-Math.PI / 2, 0.83, 0]} scale={100}>
                  <mesh name="Cylinder050_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder050_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder051" position={[-41.33, 64.91, 17.04]} rotation={[-Math.PI / 2, 0.83, 0]} scale={100}>
                  <mesh name="Cylinder051_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder051_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder052" position={[-41.33, 64.91, -16.88]} rotation={[-Math.PI / 2, 0.83, 0]} scale={100}>
                  <mesh name="Cylinder052_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder052_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder059" position={[16.46, 65.91, 41.23]} rotation={[-0.81, 0, 0]} scale={100}>
                  <mesh name="Cylinder059_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder059_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder060" position={[-16.44, 65.91, -41.23]} rotation={[-0.81, 0, 0]} scale={100}>
                  <mesh name="Cylinder060_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder060_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder061" position={[16.46, 65.91, -41.23]} rotation={[-0.81, 0, 0]} scale={100}>
                  <mesh name="Cylinder061_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder061_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere059" position={[32.53, 68.42, 31.7]} rotation={[-0.84, 0.63, 0.58]} scale={100}>
                  <mesh name="Sphere059_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere059_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere060" position={[-31.46, 68.42, 32.58]} rotation={[-0.79, -0.57, -0.5]} scale={100}>
                  <mesh name="Sphere060_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere060_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere061" position={[-32.44, 68.42, -31.6]} rotation={[-2.3, -0.63, -2.56]} scale={100}>
                  <mesh name="Sphere061_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere061_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder117" position={[0, 54.06, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder117_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder117_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder118" position={[0, 54.06, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder118_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder118_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder119" position={[0.09, 54.06, -0.09]} rotation={[-Math.PI / 2, 0, -1.57]} scale={100}>
                  <mesh name="Cylinder119_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder119_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder120" position={[0.09, 54.06, -0.09]} rotation={[-Math.PI / 2, 0, -1.57]} scale={100}>
                  <mesh name="Cylinder120_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder120_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube016" position={[0.09, 51.69, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube016_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube016_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane013" position={[-23.62, 51.48, 23.41]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane013_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane013_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane020" position={[32.97, 64.23, -33.07]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane020_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane020_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane036" position={[23.8, 51.48, 23.41]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane036_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane036_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane037" position={[-23.62, 51.48, -23.41]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane037_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane037_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube029" position={[-33.97, 62.87, 0.08]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={100}>
                  <mesh name="Cube029_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube029_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere062" position={[31.62, 68.42, -32.61]} rotation={[-2.35, 0.57, 2.64]} scale={100}>
                  <mesh name="Sphere062_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere062_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube020" position={[0.09, 46.8, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube020_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube020_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube021" position={[11.65, 46.14, 11.27]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube021_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube021_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane038" position={[23.8, 51.48, -23.41]} rotation={[-0.83, -0.57, -0.53]} scale={100}>
                  <mesh name="Plane038_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane038_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube026" position={[-11.47, 46.14, 11.27]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube026_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube026_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube028" position={[-11.47, 46.14, -11.27]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube028_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube028_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere009" position={[0.09, 44.13, 0]} rotation={[Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere009_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere009_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube027" position={[11.65, 46.14, -11.27]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube027_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube027_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube015" position={[0.09, 49.55, 42.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube015_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube015_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder013" position={[14.51, 39.59, 43.45]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder013_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder013_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder014" position={[26.1, 33.31, 44.05]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder014_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder014_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube017" position={[42.43, 49.63, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Cube017_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube017_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder020" position={[43.54, 39.59, -14.41]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder020_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder020_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder021" position={[44.14, 33.31, -26.01]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder021_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder021_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube018" position={[0.09, 49.55, -42.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cube018_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube018_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder028" position={[-14.32, 39.59, 43.45]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder028_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder028_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder029" position={[14.51, 39.59, -43.45]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder029_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder029_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder030" position={[-14.32, 39.59, -43.45]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder030_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder030_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder031" position={[-25.92, 33.31, 44.05]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder031_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder031_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder032" position={[26.1, 33.31, -44.05]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder032_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder032_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder033" position={[-25.92, 33.31, -44.05]} rotation={[-1.67, -1.07, -0.1]} scale={100}>
                  <mesh name="Cylinder033_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder033_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cube019" position={[-42.24, 49.63, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Cube019_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cube019_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder053" position={[-43.36, 39.59, -14.41]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder053_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder053_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder054" position={[43.54, 39.59, 14.41]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder054_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder054_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder055" position={[-43.36, 39.59, 14.41]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder055_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder055_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder056" position={[-43.96, 33.31, -26.01]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder056_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder056_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder057" position={[44.14, 33.31, 26.01]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder057_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder057_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder058" position={[-43.96, 33.31, 26.01]} rotation={[-0.5, -0.05, 1.55]} scale={100}>
                  <mesh name="Cylinder058_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder058_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere008" position={[43.31, 31.04, -32.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere008_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere008_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere013" position={[43.86, 31.44, -29.37]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere013_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere013_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere015" position={[-43.68, 31.44, -29.37]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere015_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere015_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane022" position={[37.72, 30, -37.6]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Plane022_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane022_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane023" position={[-37.54, 30, -37.6]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Plane023_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane023_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder026" position={[43.91, 28.12, -43.5]} rotation={[-Math.PI, 0.74, Math.PI / 2]} scale={100}>
                  <mesh name="Cylinder026_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder026_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder027" position={[-43.72, 28.12, -43.5]} rotation={[-Math.PI, 0.74, Math.PI / 2]} scale={100}>
                  <mesh name="Cylinder027_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder027_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere018" position={[32.12, 31.04, -43.22]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere018_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere018_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere019" position={[-31.93, 31.04, -43.22]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere019_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere019_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane025" position={[52, 24.71, -54.62]} rotation={[0, -0.74, -0.05]} scale={100}>
                  <mesh name="Plane025_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane025_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane026" position={[-51.82, 24.71, -54.62]} rotation={[0, -0.74, -0.05]} scale={100}>
                  <mesh name="Plane026_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane026_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere020" position={[-43.12, 31.04, -32.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere020_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere020_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere031" position={[29.47, 31.44, -43.77]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere031_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere031_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere032" position={[-29.28, 31.44, -43.77]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere032_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere032_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere016" position={[-43.68, 31.44, 29.37]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere016_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere016_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane021" position={[-37.54, 30, 37.6]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Plane021_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane021_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder025" position={[-43.72, 28.12, 43.5]} rotation={[-Math.PI, 0.74, Math.PI / 2]} scale={100}>
                  <mesh name="Cylinder025_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder025_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane024" position={[-51.82, 24.71, 54.62]} rotation={[0, -0.74, -0.05]} scale={100}>
                  <mesh name="Plane024_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane024_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere022" position={[-43.12, 31.04, 32.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere022_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere022_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere017" position={[-31.93, 31.04, 43.22]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere017_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere017_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere030" position={[-29.28, 31.44, 43.77]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere030_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere030_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane008" position={[37.72, 30, 37.6]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Plane008_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane008_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Cylinder012" position={[43.91, 28.12, 43.5]} rotation={[-Math.PI, 0.74, Math.PI / 2]} scale={100}>
                  <mesh name="Cylinder012_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Cylinder012_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere006" position={[32.12, 31.04, 43.22]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere006_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere006_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Plane009" position={[52, 24.71, 54.62]} rotation={[0, -0.74, -0.05]} scale={100}>
                  <mesh name="Plane009_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Plane009_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere011" position={[29.47, 31.44, 43.77]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Sphere011_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere011_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere014" position={[43.86, 31.44, 29.37]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere014_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere014_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Sphere021" position={[43.31, 31.04, 32.03]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={100}>
                  <mesh name="Sphere021_TT_checker_4096x4096_UV_GRID_0" geometry={nodes.Sphere021_TT_checker_4096x4096_UV_GRID_0.geometry} material={materials.TT_checker_4096x4096_UV_GRID} />
                </group>
                <group name="Torus002" position={[84.22, 52.64, 82.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus002_mat_01_0" geometry={nodes.Torus002_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder015" position={[79.34, 19.8, 77.3]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Cylinder015_mat_01_0" geometry={nodes.Cylinder015_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder016" position={[79.35, 35.23, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder016_mat_01_0" geometry={nodes.Cylinder016_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder017" position={[79.35, 51.19, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder017_mat_01_0" geometry={nodes.Cylinder017_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder018" position={[79.35, 65.04, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder018_mat_01_0" geometry={nodes.Cylinder018_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane010" position={[99.27, 59.74, 75.92]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Plane010_mat_01_0" geometry={nodes.Plane010_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane011" position={[68.2, 59.74, 60.73]} rotation={[-Math.PI / 2, 0, 2.09]} scale={100}>
                  <mesh name="Plane011_mat_01_0" geometry={nodes.Plane011_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane012" position={[70.57, 59.74, 95.23]} rotation={[-Math.PI / 2, 0, -2.09]} scale={100}>
                  <mesh name="Plane012_mat_01_0" geometry={nodes.Plane012_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus003" position={[83.41, 62.97, 81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus003_mat_01_0" geometry={nodes.Torus003_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere010" position={[69.41, 19.76, 88.36]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere010_mat_01_0" geometry={nodes.Sphere010_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder023" position={[79.35, 57.08, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder023_mat_01_0" geometry={nodes.Cylinder023_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus005" position={[-84.03, 52.64, 82.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus005_mat_01_0" geometry={nodes.Torus005_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus006" position={[84.22, 52.64, -82.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus006_mat_01_0" geometry={nodes.Torus006_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus007" position={[-84.03, 52.64, -82.33]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus007_mat_01_0" geometry={nodes.Torus007_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder034" position={[-79.15, 19.8, 77.3]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Cylinder034_mat_01_0" geometry={nodes.Cylinder034_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder035" position={[79.34, 19.8, -77.3]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Cylinder035_mat_01_0" geometry={nodes.Cylinder035_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder036" position={[-79.15, 19.8, -77.3]} rotation={[0, -0.74, 0]} scale={100}>
                  <mesh name="Cylinder036_mat_01_0" geometry={nodes.Cylinder036_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder037" position={[-79.16, 35.23, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder037_mat_01_0" geometry={nodes.Cylinder037_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder038" position={[79.35, 35.23, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder038_mat_01_0" geometry={nodes.Cylinder038_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder039" position={[-79.16, 35.23, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder039_mat_01_0" geometry={nodes.Cylinder039_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder040" position={[-79.16, 51.19, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder040_mat_01_0" geometry={nodes.Cylinder040_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder041" position={[79.35, 51.19, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder041_mat_01_0" geometry={nodes.Cylinder041_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder042" position={[-79.16, 51.19, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder042_mat_01_0" geometry={nodes.Cylinder042_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder044" position={[-79.16, 65.04, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder044_mat_01_0" geometry={nodes.Cylinder044_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder046" position={[79.35, 65.04, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder046_mat_01_0" geometry={nodes.Cylinder046_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder048" position={[-79.16, 65.04, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder048_mat_01_0" geometry={nodes.Cylinder048_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane027" position={[-99.08, 59.74, 75.92]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Plane027_mat_01_0" geometry={nodes.Plane027_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane028" position={[99.27, 59.74, -75.92]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Plane028_mat_01_0" geometry={nodes.Plane028_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane029" position={[-99.08, 59.74, -75.92]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Plane029_mat_01_0" geometry={nodes.Plane029_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane030" position={[-68.01, 59.74, 60.73]} rotation={[-Math.PI / 2, 0, 2.09]} scale={100}>
                  <mesh name="Plane030_mat_01_0" geometry={nodes.Plane030_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane031" position={[68.2, 59.74, -60.73]} rotation={[-Math.PI / 2, 0, 2.09]} scale={100}>
                  <mesh name="Plane031_mat_01_0" geometry={nodes.Plane031_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane032" position={[-68.01, 59.74, -60.73]} rotation={[-Math.PI / 2, 0, 2.09]} scale={100}>
                  <mesh name="Plane032_mat_01_0" geometry={nodes.Plane032_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane033" position={[-70.39, 59.74, 95.23]} rotation={[-Math.PI / 2, 0, -2.09]} scale={100}>
                  <mesh name="Plane033_mat_01_0" geometry={nodes.Plane033_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane034" position={[70.57, 59.74, -95.23]} rotation={[-Math.PI / 2, 0, -2.09]} scale={100}>
                  <mesh name="Plane034_mat_01_0" geometry={nodes.Plane034_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Plane035" position={[-70.39, 59.74, -95.23]} rotation={[-Math.PI / 2, 0, -2.09]} scale={100}>
                  <mesh name="Plane035_mat_01_0" geometry={nodes.Plane035_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus008" position={[-83.22, 62.97, 81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus008_mat_01_0" geometry={nodes.Torus008_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus009" position={[83.41, 62.97, -81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus009_mat_01_0" geometry={nodes.Torus009_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus010" position={[-83.22, 62.97, -81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus010_mat_01_0" geometry={nodes.Torus010_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere023" position={[89.49, 19.76, 66.42]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere023_mat_01_0" geometry={nodes.Sphere023_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere024" position={[-69.22, 19.76, 88.36]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere024_mat_01_0" geometry={nodes.Sphere024_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere025" position={[-89.31, 19.76, 66.42]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere025_mat_01_0" geometry={nodes.Sphere025_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere026" position={[69.41, 19.76, -88.36]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere026_mat_01_0" geometry={nodes.Sphere026_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere027" position={[89.49, 19.76, -66.42]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere027_mat_01_0" geometry={nodes.Sphere027_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere028" position={[-69.22, 19.76, -88.36]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere028_mat_01_0" geometry={nodes.Sphere028_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Sphere029" position={[-89.31, 19.76, -66.42]} rotation={[0, -0.74, Math.PI]} scale={100}>
                  <mesh name="Sphere029_mat_01_0" geometry={nodes.Sphere029_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder062" position={[-79.16, 57.08, 77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder062_mat_01_0" geometry={nodes.Cylinder062_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder063" position={[79.35, 57.08, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder063_mat_01_0" geometry={nodes.Cylinder063_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cylinder064" position={[-79.16, 57.08, -77.3]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Cylinder064_mat_01_0" geometry={nodes.Cylinder064_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cube023" position={[78.72, 49.84, 55.67]} rotation={[-Math.PI / 2, 0, 0.05]} scale={100}>
                  <mesh name="Cube023_mat_01_0" geometry={nodes.Cube023_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cube031" position={[78.72, 49.84, 55.67]} rotation={[-Math.PI / 2, 0, 0.05]} scale={100}>
                  <mesh name="Cube031_mat_01_0" geometry={nodes.Cube031_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cube034" position={[78.72, 49.84, 55.67]} rotation={[-Math.PI / 2, 0, 0.05]} scale={100}>
                  <mesh name="Cube034_mat_01_0" geometry={nodes.Cube034_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Cube036" position={[78.72, 49.84, 55.67]} rotation={[-Math.PI / 2, 0, 0.05]} scale={100}>
                  <mesh name="Cube036_mat_01_0" geometry={nodes.Cube036_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus019" position={[83.41, 55.19, -81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus019_mat_01_0" geometry={nodes.Torus019_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus020" position={[83.41, 54.73, 81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus020_mat_01_0" geometry={nodes.Torus020_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus021" position={[-83.22, 54.73, 81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus021_mat_01_0" geometry={nodes.Torus021_mat_01_0.geometry} material={materials.mat_01} />
                </group>
                <group name="Torus022" position={[-83.22, 54.73, -81.6]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <mesh name="Torus022_mat_01_0" geometry={nodes.Torus022_mat_01_0.geometry} material={materials.mat_01} />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('./models/quadrocopter_drone_-_animated/scene-transformed.glb')
