import './style.css'
import { ScrollControls, Scroll } from '@react-three/drei'
import robot from './robot1.png'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function aipad() {
  return (
    <>
      <ScrollControls pages={6} damping={0.25}>
        <Scroll html stye={{ width: '100%' }}>
          <Container style={{ position: 'relative' }}>
            {/* row1 */}
            <Row>
              <Col xs={6}>
                <div className="gitbook-root">
                  <div className="css-175oi2r r-13awgt0 r-12vffkv">
                    <div className="css-175oi2r r-13awgt0 r-12vffkv">
                      <header
                        data-rnwrdesktop-1ph75f1="true"
                        data-rnwr700-sga3zk="true"
                        className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-14lw9ot r-nzcix3 r-qklmqi r-gtdqiz r-ipm5af r-184en5c r-18u37iz r-1awozwy"
                      >
                        <div className="css-175oi2r r-6koalj r-18u37iz r-1jgb5lz r-ty80ws r-1pn2ns4 r-13qz1uu r-1awozwy">
                          <div
                            aria-label="Show Table of Content"
                            role="button"
                            data-rnwrdesktop-hidden="true"
                            data-rnwr700-visible="true"
                            data-rnwi-1vckr1u-hover-focus="true"
                            data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                            data-rnwi-handle="button"
                            tabindex="0"
                            className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-rs99b7 r-18u37iz r-18kxxzh r-1777fci r-1ny4l3l r-eu3ka r-1bnj018 r-1kb76zh r-18c69zk r-mk0yit r-1aockid"
                            style={{ transitionDuration: '0.15s' }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              preserveAspectRatio="xMidYMid meet"
                              data-rnwibutton-1bnj018-hover-focus="true"
                              data-rnwi-handle="nearest"
                              className="r-h7gdob"
                              style={{
                                verticalAlign: 'middle',
                                width: '18px',
                                height: '18px',
                              }}
                            >
                              <path d="M3 12h18M3 6h18M3 18h18"></path>
                            </svg>
                          </div>
                          <a
                            href="/aipad/"
                            aria-label="AIPAD"
                            data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                            data-rnwi-handle="nearest"
                            data-rnwrdesktop-iyfy8q-1qhn6m8-11g3r6m-1h0z5md="true"
                            data-rnwr700-iyfy8q-gy4na3-i023vh-1wbh5a2-1h0z5md-="true"
                            className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1awozwy"
                            style={{ transitionDuration: '0.15s' }}
                          >
                            {/* , dataTestId='public.headerHomeLink' */}
                            <div className="css-175oi2r r-6wscbn r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1h0z5md r-1awozwy">
                              <div className="css-175oi2r">
                                <div
                                  data-rnwrdesktop-visible="true"
                                  data-rnwr700-hidden="true"
                                  className="css-175oi2r"
                                >
                                  <div
                                    className="css-175oi2r r-18u37iz r-1awozwy r-1777fci r-z2wwpe r-14lw9ot r-1l94q7l r-88pszg"
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      overflowX: 'hidden',
                                      overflowY: 'hidden',
                                    }}
                                  >
                                    <div
                                      className="css-175oi2r r-4qtqp9 r-1q9jyb7 r-1oec5bt r-u8s1d"
                                      style={{ width: '3px', height: '80px' }}
                                    >
                                      <img src={robot} alt="" />
                                    </div>
                                    <div
                                      className="css-175oi2r r-4qtqp9 r-bnwqim"
                                      style={{
                                        width: '3px',
                                        height: '28.16px',
                                      }}
                                    >
                                      <img src={robot} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  data-rnwrdesktop-hidden="true"
                                  data-rnwr700-visible="true"
                                  className="css-175oi2r"
                                >
                                  <div
                                    className="css-175oi2r r-18u37iz r-1awozwy r-1777fci r-z2wwpe r-14lw9ot r-1l94q7l r-88pszg"
                                    style={{
                                      width: '24px',
                                      height: '24px',
                                      overflowX: 'hidden',
                                      overflowY: 'hidden',
                                    }}
                                  >
                                    <div
                                      className="css-175oi2r r-4qtqp9 r-1q9jyb7 r-1oec5bt r-u8s1d"
                                      style={{ width: '3px', height: '48px' }}
                                    >
                                      <img src={robot} alt="" />
                                    </div>
                                    <div
                                      className="css-175oi2r r-4qtqp9 r-bnwqim"
                                      style={{
                                        width: '3px',
                                        height: '16.64px',
                                      }}
                                    >
                                      <img src={robot} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                dir="auto"
                                data-rnwrdesktop-gg6oyi-adyw6z-135wba7-b88u0q="true"
                                data-rnwr700-gg6oyi-ubezar-135wba7-1kfrs79="true"
                                className="css-1rynq56 r-1udh08x"
                              >
                                <span
                                  data-rnwinearest-1nf4jbm-="true"
                                  data-rnwinearest--1ghhsy9-hover-focus="true"
                                  data-rnwi-handle="nearest"
                                  className="css-1qaijid r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a"
                                >
                                  AIPAD
                                </span>
                              </div>
                            </div>
                          </a>
                          <div
                            data-rnwr700-hidden="true"
                            className="css-175oi2r r-18u37iz r-17s6mgv r-1awozwy r-1ro0kt6 r-16y2uox r-1wbh5a2 r-1qhn6m8"
                          ></div>
                          <div
                            data-rnwrdesktop-visible="true"
                            data-rnwr700-hidden="true"
                            className="css-175oi2r r-1jj8364 r-puj83k r-1pyaxff r-16vg0q1 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                          >
                            <div
                              aria-label="Search…"
                              data-rnwi-190qawg-hover-focus="true"
                              data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                              data-rnwi-handle="nearest"
                              tabindex="0"
                              className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-42olwf r-kdyh1x r-rs99b7 r-eu3ka r-13qz1uu r-18u37iz r-1wtj0ep r-1awozwy r-1qhn6m8 r-1pyaxff"
                              style={{
                                backgroundColor: 'rgba(0,0,0,0.18)',
                                transitionDuration: '0.15s',
                              }}
                            >
                              <div className="css-175oi2r r-18u37iz r-1awozwy">
                                <svg
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  preserveAspectRatio="xMidYMid meet"
                                  className="r-1bnj018 r-1kb76zh"
                                  style={{
                                    verticalAlign: 'middle',
                                    width: '16px',
                                    height: '16px',
                                  }}
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.5 4a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM2 10.5a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.093 15.093a1 1 0 011.414 0l5.2 5.2a1 1 0 01-1.414 1.414l-5.2-5.2a1 1 0 010-1.414z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <div
                                  dir="auto"
                                  data-rnwinearest-1nf4jbm-hover-focus="true"
                                  data-rnwi-handle="nearest"
                                  className="css-1rynq56 r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                >
                                  Search…
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            data-rnwrdesktop-hidden="true"
                            data-rnwr700-visible="true"
                            className="css-175oi2r r-1jj8364 r-puj83k r-1pyaxff"
                          >
                            <div
                              aria-label="Search…"
                              role="button"
                              data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                              data-rnwi-handle="button"
                              tabindex="0"
                              className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-rs99b7 r-18u37iz r-18kxxzh r-1777fci r-1ny4l3l r-z2wwpe r-mabqd8 r-1bnj018 r-mk0yit r-1yvhtrz"
                              style={{ transitionDuration: '0.15s' }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                preserveAspectRatio="xMidYMid meet"
                                data-rnwibutton--1ghhsy9-hover-focus="true"
                                data-rnwi-handle="nearest"
                                className="r-h7gdob"
                                style={{
                                  verticalAlign: 'middle',
                                  width: '16px',
                                  height: '16px',
                                }}
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.5 4a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM2 10.5a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M15.093 15.093a1 1 0 011.414 0l5.2 5.2a1 1 0 01-1.414 1.414l-5.2-5.2a1 1 0 010-1.414z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </header>
                      <div
                        data-rnwrdesktop-hidden="true"
                        data-rnwr700-visible="true"
                        className="css-175oi2r"
                      ></div>
                      <div
                        data-rnwrdesktop-18u37iz="true"
                        data-rnwr700-eqz5dr="true"
                        className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2"
                      >
                        <div
                          data-rnwrdesktop-visible="true"
                          data-rnwr1430-visible="true"
                          data-rnwr700-hidden="true"
                          className="css-175oi2r r-k200y r-14lw9ot r-18ayb63 r-13l2t4g r-12dqhl9 r-1rnoaur r-gtdqiz r-1fo40xd r-18u37iz r-17s6mgv"
                          style={{
                            width:
                              'max(300px, calc((100vw - 910px) / 2 - 0px))',
                          }}
                        >
                          {/* , dataTestid='page.desktopTableOfContents' */}
                          <nav
                            aria-label="Table of contents"
                            className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ipicw7 r-eqz5dr"
                          >
                            <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-puj83k">
                              <div
                                data-rnwrdesktop-hidden="true"
                                data-rnwr700-visible="true"
                                className="css-175oi2r r-14lw9ot r-nzcix3 r-qklmqi r-sga3zk r-1pn2ns4 r-1wtj0ep r-1awozwy r-18u37iz"
                              >
                                <div
                                  aria-label="Close"
                                  role="button"
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="button"
                                  tabindex="0"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-rs99b7 r-18u37iz r-18kxxzh r-1777fci r-1ny4l3l r-z2wwpe r-eu3ka r-1bnj018 r-1kihuf0 r-mk0yit r-1aockid"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <svg
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid meet"
                                    data-rnwibutton--1ghhsy9-hover-focus="true"
                                    data-rnwi-handle="nearest"
                                    className="r-h7gdob"
                                    style={{
                                      verticalAlign: 'middle',
                                      width: '18px',
                                      height: '18px',
                                    }}
                                  >
                                    <path
                                      d="M12.424 4.424a.6.6 0 00-.848-.848L8 7.15 4.424 3.576a.6.6 0 10-.848.848L7.15 8l-3.575 3.576a.6.6 0 00.848.848L8 8.85l3.576 3.575a.6.6 0 00.848-.848L8.85 8l3.575-3.576z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <div className="css-175oi2r r-150rngu r-eqz5dr r-11yh6sk r-1rnoaur r-1sncvnh r-1ro0kt6 r-16y2uox r-1wbh5a2">
                                <div className="css-175oi2r r-1yzf0co r-1sc18lr">
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-1b00too-hover="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh r-15zivkp"
                                      style={{ transitionDuration: '0.15s' }}
                                    >
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                      >
                                        Overview
                                      </div>
                                    </a>
                                  </div>
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/leaderboard-tier-system"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh r-15zivkp"
                                      style={{
                                        backgroundColor:
                                          'rgba(133,163,224,0.10)',
                                        transitionDuration: '0.15s',
                                      }}
                                    >
                                      <div className="css-175oi2r r-88pszg">
                                        <div
                                          className="css-175oi2r r-4qtqp9 r-bnwqim r-1ghhsy9"
                                          style={{
                                            width: '17.92px',
                                            height: '17.92px',
                                          }}
                                        >
                                          <span
                                            className="emj-objects _1fa9c"
                                            role="img"
                                            title="ladder"
                                            aria-label="ladder"
                                            style={{
                                              transform:
                                                'translate(-50%, -50%) scale(0.28)',
                                            }}
                                          >
                                            🪜
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-hbpseb r-1ghhsy9 r-b88u0q"
                                      >
                                        Leaderboard Tier System
                                      </div>
                                    </a>
                                  </div>
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/ido-information"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-1b00too-hover="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh r-15zivkp"
                                      style={{ transitionDuration: '0.15s' }}
                                    >
                                      <div className="css-175oi2r r-88pszg">
                                        <div
                                          className="css-175oi2r r-4qtqp9 r-bnwqim r-1bnj018"
                                          style={{
                                            width: '17.92px',
                                            height: '17.92px',
                                          }}
                                        >
                                          <span
                                            className="emj-people _1f60a"
                                            role="img"
                                            title="smiling face with smiling eyes"
                                            aria-label="smiling face with smiling eyes"
                                            style={{
                                              transform:
                                                'translate(-50%, -50%) scale(0.28)',
                                            }}
                                          >
                                            😊
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                      >
                                        IDO Information
                                      </div>
                                    </a>
                                  </div>
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/tokenomics"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-1b00too-hover="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh r-15zivkp"
                                      style={{ transitionDuration: '0.15s' }}
                                    >
                                      <div className="css-175oi2r r-88pszg">
                                        <div
                                          className="css-175oi2r r-4qtqp9 r-bnwqim r-1bnj018"
                                          style={{
                                            width: '17.92px',
                                            height: '17.92px',
                                          }}
                                        >
                                          <span
                                            className="emj-objects _1f4b0"
                                            role="img"
                                            title="money bag"
                                            aria-label="money bag"
                                            style={{
                                              transform:
                                                'translate(-50%, -50%) scale(0.28)',
                                            }}
                                          >
                                            💰
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                      >
                                        Tokenomics
                                      </div>
                                    </a>
                                  </div>
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/kyc-know-your-customer"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-1b00too-hover="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh r-15zivkp"
                                      style={{ transitionDuration: '0.15s' }}
                                    >
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                      >
                                        ✅ KYC - Know Your Customer
                                      </div>
                                    </a>
                                  </div>
                                  <div className="css-175oi2r">
                                    <a
                                      href="/aipad/socials"
                                      data-rnwrdesktop-fnigne="true"
                                      data-rnwr700-13l2t4g="true"
                                      data-rnwi-1b00too-hover="true"
                                      data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                      data-rnwi-handle="nearest"
                                      className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-z2wwpe r-rs99b7 r-18u37iz r-1ceczpf r-1pn2ns4 r-1kb76zh"
                                      style={{ transitionDuration: '0.15s' }}
                                    >
                                      <div className="css-175oi2r r-88pszg">
                                        <div
                                          className="css-175oi2r r-4qtqp9 r-bnwqim r-1bnj018"
                                          style={{
                                            width: '17.92px',
                                            height: '17.92px',
                                          }}
                                        >
                                          <span
                                            className="emj-objects _1f4bb"
                                            role="img"
                                            title="laptop computer"
                                            aria-label="laptop computer"
                                            style={{
                                              transform:
                                                'translate(-50%, -50%) scale(0.28)',
                                            }}
                                          >
                                            💻
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        dir="auto"
                                        className="css-1rynq56 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-oyd9sg r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                      >
                                        Socials
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="css-175oi2r r-1p0dtai r-u8s1d r-13qz1uu r-184en5c">
                              <div
                                className="css-175oi2r r-10ptun7"
                                style={{
                                  backgroundImage:
                                    'linear-gradient(3.141592653589793rad,#ffffff00,#ffffff)',
                                }}
                              ></div>
                              <div className="css-175oi2r r-ymttw5 r-1l7z4oj r-14lw9ot">
                                <a
                                  href="https://www.gitbook.com/?utm_source=content&amp;utm_medium=trademark&amp;utm_campaign=M1QI8KZfUlh0F9KZb6ER"
                                  data-rnwi--1ghhsy9-hover-focus="true"
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="nearest"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1awozwy r-1b00too r-1xfd6ze r-5njf8e r-ymttw5"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <svg
                                    viewBox="0 0 1000 1000"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid meet"
                                    data-rnwi--1ghhsy9-hover-focus="true"
                                    data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                    data-rnwi-handle="nearest"
                                    className="r-1rasi3h"
                                    style={{
                                      verticalAlign: 'middle',
                                      width: '40px',
                                      height: '40px',
                                    }}
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M562.168 159.724l325.95 162.727c15.062 7.519 15.298 28.898.404 36.746L465.19 582.283a82.875 82.875 0 01-75.639.83L123.74 450.409c-32.376-12.972-68.568 10.748-68.568 46.474 0 28.728 16.256 54.991 41.99 67.839l266.48 133.036c16.267-16.537 38.918-26.795 63.967-26.795 24.334 0 46.404 9.68 62.558 25.394L822.075 521.45a89.893 89.893 0 01-1.385-15.755c0-49.44 40.14-89.519 89.655-89.519S1000 456.255 1000 505.695c0 49.439-40.14 89.518-89.655 89.518-24.21 0-46.178-9.581-62.31-25.153L515.94 745.065a90.036 90.036 0 011.324 15.417c0 49.439-40.14 89.518-89.655 89.518s-89.655-40.079-89.655-89.518c0-4.572.343-9.063 1.006-13.451L68.622 612.068C26.566 591.072 0 548.153 0 501.205v-26.15c0-35.755 19.82-68.574 51.49-85.261l435.039-229.24a82.87 82.87 0 0175.639-.83zM427.609 794.912c19.044 0 34.483-15.415 34.483-34.43 0-19.016-15.439-34.431-34.483-34.431-19.044 0-34.482 15.415-34.482 34.431 0 19.015 15.438 34.43 34.482 34.43zm517.219-289.217c0 19.015-15.438 34.43-34.483 34.43-19.044 0-34.482-15.415-34.482-34.43s15.438-34.43 34.482-34.43c19.045 0 34.483 15.415 34.483 34.43z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-1qhn6m8">
                                    <div
                                      dir="auto"
                                      data-rnwinearest--1ghhsy9-hover-focus="true"
                                      data-rnwi-handle="nearest"
                                      className="css-1rynq56 r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1rasi3h"
                                    >
                                      Powered By{' '}
                                      <span className="css-1qaijid r-b88u0q">
                                        GitBook
                                      </span>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </nav>
                        </div>
                        <div
                          className="css-175oi2r r-13awgt0"
                          style={{ backgroundColor: 'rgba(255,255,255,1.00)' }}
                        >
                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz">
                            <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci">
                              <main className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-eqz5dr">
                                <div
                                  data-rnwrdesktop-1q6rxnj="true"
                                  data-rnwr1490-1777fci="true"
                                  data-rnwr700-i023vh="true"
                                  className="css-175oi2r r-18u37iz r-1777fci"
                                >
                                  <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                    <div className="css-175oi2r r-eqz5dr r-1ygmrgt r-1peese0">
                                      <div className="css-175oi2r r-18u37iz">
                                        <div
                                          data-rnwrdesktop-1x93onp-h3s6tt="true"
                                          data-rnwr700-285fr0-iyfy8q-eu3ka="true"
                                          className="css-175oi2r r-18u37iz r-17s6mgv r-1awozwy"
                                        >
                                          <div className="css-175oi2r r-ymttw5">
                                            <div
                                              className="css-175oi2r r-4qtqp9 r-bnwqim"
                                              style={{
                                                width: '24.32px',
                                                height: '24.32px',
                                              }}
                                            >
                                              <span
                                                className="emj-objects _1fa9c"
                                                role="img"
                                                title="ladder"
                                                aria-label="ladder"
                                                style={{
                                                  transform:
                                                    'translate(-50%, -50%) scale(0.38)',
                                                }}
                                              >
                                                🪜
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2">
                                          <h1
                                            data-rnwrdesktop-gg6oyi-xb2eav-1dn12g7-b88u0q="true"
                                            data-rnwr700-gg6oyi-1ui5ee8-nwxazl-b88u0q="true"
                                            className="r-1xnzce8 r-crgep1 r-1nf4jbm"
                                            data-testid="page.title"
                                          >
                                            Leaderboard Tier System
                                          </h1>
                                        </div>
                                        <div
                                          data-rnwrdesktop-hidden="true"
                                          data-rnwr1490-visible="true"
                                          data-rnwr700-visible="true"
                                          className="css-175oi2r r-18u37iz r-1awozwy"
                                        >
                                          <div
                                            aria-label="Page actions"
                                            role="button"
                                            data-rnwi-1vckr1u-hover-focus="true"
                                            data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                            data-rnwi-handle="button"
                                            tabindex="0"
                                            className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-1awozwy r-42olwf r-rs99b7 r-18u37iz r-18kxxzh r-1777fci r-1ny4l3l r-eu3ka r-1bnj018 r-18c69zk r-mk0yit r-1aockid"
                                            style={{
                                              transitionDuration: '0.15s',
                                            }}
                                          >
                                            {/* data-testid="pageCompactToolbar.paletteButton" */}
                                            <svg
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              preserveAspectRatio="xMidYMid meet"
                                              data-rnwibutton-1bnj018-hover-focus="true"
                                              data-rnwi-handle="nearest"
                                              className="r-h7gdob"
                                              style={{
                                                verticalAlign: 'middle',
                                                width: '18px',
                                                height: '18px',
                                              }}
                                            >
                                              <path
                                                d="M8 2.4a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2zM8 6.9a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2zM8 11.4a1.1 1.1 0 100 2.2 1.1 1.1 0 000-2.2z"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-rnwrdesktop-13lvk87="true"
                                        data-rnwr700-1ow6zhx="true"
                                        className="css-175oi2r r-1wzrnnt"
                                      >
                                        <div className="css-175oi2r">
                                          <div
                                            dir="auto"
                                            className="css-1rynq56 r-gg6oyi r-adyw6z r-16dba41 r-17rnw9f r-1rasi3h r-1xnzce8"
                                          >
                                            AIPad LEADERBOARD - A Deep Dive into
                                            AIPad&#x27;s Leaderboard
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        data-rnwrdesktop-13lvk87="true"
                                        data-rnwr700-1ow6zhx="true"
                                        className="css-175oi2r"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  data-rnwrdesktop-1uwte3a="true"
                                  className="css-175oi2r"
                                >
                                  <div className="css-175oi2r r-bnwqim">
                                    <div
                                      data-testid="page.contentEditor"
                                      data-slate-editor="true"
                                      data-document-key="29b470226263472cb7d3a494d2c07842"
                                      data-key="29b470226263472cb7d3a494d2c07842"
                                      autoCorrect="on"
                                      spellcheck="true"
                                      style={{
                                        outline: 'none',
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 64px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="ffb3729b7eb24c91896ca9ea39e2c691"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1ygmrgt r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="ffb3729b7eb24c91896ca9ea39e2c691"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <h2
                                                      className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-18u37iz r-obd0qt"
                                                      id="leaderboard-introduction"
                                                    >
                                                      <div
                                                        dir="auto"
                                                        data-rnwrdesktop-gg6oyi-1x35g6-37tt59-b88u0q="true"
                                                        data-rnwr700-gg6oyi-adyw6z-135wba7-b88u0q="true"
                                                        className="css-1rynq56 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                        id="text-leaderboard-introduction"
                                                      >
                                                        <span data-key="a54cbebf86db4626990d2c56fe7077c2">
                                                          <span data-offset-key="a54cbebf86db4626990d2c56fe7077c2:0">
                                                            Leaderboard
                                                            Introduction
                                                          </span>
                                                        </span>
                                                        <a
                                                          href="#leaderboard-introduction"
                                                          aria-label="Direct link to heading"
                                                          data-rnwi-1rasi3h-="true"
                                                          data-rnwi--1ghhsy9-hover-focus="true"
                                                          data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                                          data-rnwi-handle="nearest"
                                                          className="css-1qaijid r-1awozwy r-xoduu5 r-1jkjb r-orgf3d r-1loqt21"
                                                        >
                                                          <svg
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            role="presentation"
                                                            style={{
                                                              verticalAlign:
                                                                'middle',
                                                              width: '20px',
                                                              height: '20px',
                                                            }}
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.42 1.925a.6.6 0 01.405.745L7.167 4.9h3.998l.76-2.57a.6.6 0 111.15.34l-.659 2.23H14a.6.6 0 010 1.2h-1.938l-1.123 3.8H13a.6.6 0 010 1.2h-2.415l-.76 2.57a.6.6 0 01-1.15-.34l.658-2.23H5.335l-.76 2.57a.6.6 0 11-1.15-.34l.658-2.23H2a.6.6 0 110-1.2h2.438l1.123-3.8H3a.6.6 0 010-1.2h2.915l.76-2.57a.6.6 0 01.745-.405zM6.812 6.1L5.689 9.9h3.999l1.123-3.8H6.812z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 112px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="eba0c0187f8840209e5390dad112a8d1"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="eba0c0187f8840209e5390dad112a8d1"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="ad1d357cf48a4cb79b05fb26598b9f3d">
                                                        <span data-offset-key="ad1d357cf48a4cb79b05fb26598b9f3d:0">
                                                          AIPad is pleased to
                                                          announce the
                                                          introduction of a
                                                          gamified leaderboard,
                                                          which offers our
                                                          community innovative
                                                          and interactive
                                                          methods of
                                                          participating in IDOs
                                                          hosted on our
                                                          platform. The
                                                          leaderboard is
                                                          accessible to all
                                                          individuals possessing
                                                          a KYC-approved wallet.
                                                          Your placement and
                                                          score on the
                                                          leaderboard will
                                                          determine your tier
                                                          and subsequent
                                                          allocation.
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 40px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="44aef37fdf194857a001851cc29b3e75"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="44aef37fdf194857a001851cc29b3e75"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="70ee32aac83e49fda69e8f2b4e844dce">
                                                        <strong
                                                          data-slate-leaf="true"
                                                          data-offset-key="70ee32aac83e49fda69e8f2b4e844dce:0"
                                                          className="r-crgep1 r-b88u0q"
                                                        >
                                                          Token Holding
                                                        </strong>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 112px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="52e0b95d96b14e7a86061783c62fb4ee"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="52e0b95d96b14e7a86061783c62fb4ee"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="cd2026eb870e466fa6a627f303e3943b">
                                                        <span data-offset-key="cd2026eb870e466fa6a627f303e3943b:0">
                                                          AIPad&#x27;s
                                                          leaderboard includes a
                                                          holding component that
                                                          incentivizes users to
                                                          maintain their tokens
                                                          for a predetermined
                                                          duration. This
                                                          component&#x27;s score
                                                          is calculated by
                                                          capturing users&#x27;
                                                          token holdings once
                                                          daily over 21 days,
                                                          during which a
                                                          multiplier is applied,
                                                          beginning at 2x on day
                                                          -21 and gradually
                                                          decreasing to 1x on
                                                          day -1.{' '}
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 64px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="717cb88af5024b81ba61edceeade8f09"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="717cb88af5024b81ba61edceeade8f09"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="79b6b33ff63d4f08bc3c335226cfba72">
                                                        <span data-offset-key="79b6b33ff63d4f08bc3c335226cfba72:0">
                                                          The score is
                                                          calculated based on
                                                          token holding. We may
                                                          later introduce
                                                          another element to
                                                          determine the score on
                                                          the leaderboard. This
                                                          will be communicated
                                                          accordingly.
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 88px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="995f1742d9d64cc7b1f7d66a81959720"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="995f1742d9d64cc7b1f7d66a81959720"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="096b2fb7f1f04deca4fcf3fd5402830e">
                                                        <span data-offset-key="096b2fb7f1f04deca4fcf3fd5402830e:0">
                                                          The score is
                                                          calculated based on
                                                          token holding so
                                                          tokens sold will not
                                                          be included in the
                                                          calculation. We may
                                                          introduce another
                                                          element for
                                                          leaderboard boosts.
                                                          Further details will
                                                          be provided in due
                                                          course. Stay tuned for
                                                          updates.
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 105px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="a49081771e514001a702ea2fa7334c66"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1ygmrgt r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="a49081771e514001a702ea2fa7334c66"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <h2
                                                      className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-18u37iz r-obd0qt"
                                                      id="the-leaderboard"
                                                    >
                                                      <div
                                                        dir="auto"
                                                        data-rnwrdesktop-gg6oyi-1x35g6-37tt59-b88u0q="true"
                                                        data-rnwr700-gg6oyi-adyw6z-135wba7-b88u0q="true"
                                                        className="css-1rynq56 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                        id="text-the-leaderboard"
                                                      >
                                                        <span data-key="d3f8a280aa364a679a57cbb3d0238f0c">
                                                          <span data-offset-key="d3f8a280aa364a679a57cbb3d0238f0c:0">
                                                            The Leaderboard
                                                          </span>
                                                        </span>
                                                        <a
                                                          href="#the-leaderboard"
                                                          aria-label="Direct link to heading"
                                                          data-rnwi-1rasi3h-="true"
                                                          data-rnwi--1ghhsy9-hover-focus="true"
                                                          data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                                          data-rnwi-handle="nearest"
                                                          className="css-1qaijid r-1awozwy r-xoduu5 r-1jkjb r-orgf3d r-1loqt21"
                                                        >
                                                          <svg
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            role="presentation"
                                                            style={{
                                                              verticalAlign:
                                                                'middle',
                                                              width: '20px',
                                                              height: '20px',
                                                            }}
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.42 1.925a.6.6 0 01.405.745L7.167 4.9h3.998l.76-2.57a.6.6 0 111.15.34l-.659 2.23H14a.6.6 0 010 1.2h-1.938l-1.123 3.8H13a.6.6 0 010 1.2h-2.415l-.76 2.57a.6.6 0 01-1.15-.34l.658-2.23H5.335l-.76 2.57a.6.6 0 11-1.15-.34l.658-2.23H2a.6.6 0 110-1.2h2.438l1.123-3.8H3a.6.6 0 010-1.2h2.915l.76-2.57a.6.6 0 01.745-.405zM6.812 6.1L5.689 9.9h3.999l1.123-3.8H6.812z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 112px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="065ea0d4e26844ce908b5ebad0353bce"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="065ea0d4e26844ce908b5ebad0353bce"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="57f4eea843164854bb4265b9eb34151a">
                                                        <span data-offset-key="57f4eea843164854bb4265b9eb34151a:0">
                                                          AIPad&#x27;s website
                                                          will display the
                                                          leaderboard, which
                                                          showcases user scores.
                                                          The top 1,000 users on
                                                          the leaderboard are
                                                          guaranteed an
                                                          allocation in the
                                                          project. AIPad has
                                                          established allocation
                                                          size tiers, including
                                                          the top 100, 101-200,
                                                          and so forth. Users
                                                          not in the confirmed
                                                          allocation list will
                                                          be entered into a
                                                          lottery draw:
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 152px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="6036ad4543ff42e294ef0e72e0d81fc0"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-95jzfe r-1l7z4oj"
                                                >
                                                  <div
                                                    data-block-content="6036ad4543ff42e294ef0e72e0d81fc0"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <ul
                                                      data-key="6036ad4543ff42e294ef0e72e0d81fc0"
                                                      className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010"
                                                    >
                                                      <div className="css-175oi2r">
                                                        <div className="css-175oi2r">
                                                          <li
                                                            data-key="f086df04d736474db83195b7472707f1"
                                                            className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-wk8lta r-iphfwy"
                                                          >
                                                            <div
                                                              data-block-content="f086df04d736474db83195b7472707f1"
                                                              className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-6koalj r-18u37iz r-bnwqim"
                                                            >
                                                              <div
                                                                contenteditable="false"
                                                                className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1awozwy r-6koalj r-18u37iz r-1jkjb r-1kb76zh"
                                                                style={{
                                                                  height:
                                                                    '24px',
                                                                }}
                                                              >
                                                                <svg
                                                                  viewBox="0 0 8 16"
                                                                  preserveAspectRatio="xMidYMid meet"
                                                                  className="r-1nf4jbm"
                                                                  style={{
                                                                    verticalAlign:
                                                                      'middle',
                                                                    width:
                                                                      '12px',
                                                                    height:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  <path
                                                                    d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                                                                    fill="currentColor"
                                                                    fill-rule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                              <div className="css-175oi2r r-13awgt0">
                                                                <div
                                                                  data-key="e28b87dcac924aebb75d61f3487bddc1"
                                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010"
                                                                >
                                                                  <div
                                                                    dir="auto"
                                                                    className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                                  >
                                                                    <span data-key="e8bcbc70c3e643098416658002b42210">
                                                                      <span data-offset-key="e8bcbc70c3e643098416658002b42210:0">
                                                                        Rank
                                                                        1001 to
                                                                        2000 - 4
                                                                        lottery
                                                                        tickets
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                      <div className="css-175oi2r">
                                                        <div className="css-175oi2r">
                                                          <li
                                                            data-key="714db3b7b3b849319dc64d64aa1b16ac"
                                                            className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1h8ys4a r-iphfwy"
                                                          >
                                                            <div
                                                              data-block-content="714db3b7b3b849319dc64d64aa1b16ac"
                                                              className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-6koalj r-18u37iz r-bnwqim"
                                                            >
                                                              <div
                                                                contenteditable="false"
                                                                className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1awozwy r-6koalj r-18u37iz r-1jkjb r-1kb76zh"
                                                                style={{
                                                                  height:
                                                                    '24px',
                                                                }}
                                                              >
                                                                <svg
                                                                  viewBox="0 0 8 16"
                                                                  preserveAspectRatio="xMidYMid meet"
                                                                  className="r-1nf4jbm"
                                                                  style={{
                                                                    verticalAlign:
                                                                      'middle',
                                                                    width:
                                                                      '12px',
                                                                    height:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  <path
                                                                    d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                                                                    fill="currentColor"
                                                                    fill-rule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                              <div className="css-175oi2r r-13awgt0">
                                                                <div
                                                                  data-key="b6c233f92b4e43858159a4f022196d40"
                                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010"
                                                                >
                                                                  <div
                                                                    dir="auto"
                                                                    className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                                  >
                                                                    <span data-key="672a9bf85e734bb4be55d4d8b6f5fecb">
                                                                      <span data-offset-key="672a9bf85e734bb4be55d4d8b6f5fecb:0">
                                                                        Rank
                                                                        2001 to
                                                                        3000 - 3
                                                                        lottery
                                                                        tickets
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                      <div className="css-175oi2r">
                                                        <div className="css-175oi2r">
                                                          <li
                                                            data-key="2724defa5e2146e7acdcf0f2a5d66341"
                                                            className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1h8ys4a r-iphfwy"
                                                          >
                                                            <div
                                                              data-block-content="2724defa5e2146e7acdcf0f2a5d66341"
                                                              className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-6koalj r-18u37iz r-bnwqim"
                                                            >
                                                              <div
                                                                contenteditable="false"
                                                                className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1awozwy r-6koalj r-18u37iz r-1jkjb r-1kb76zh"
                                                                style={{
                                                                  height:
                                                                    '24px',
                                                                }}
                                                              >
                                                                <svg
                                                                  viewBox="0 0 8 16"
                                                                  preserveAspectRatio="xMidYMid meet"
                                                                  className="r-1nf4jbm"
                                                                  style={{
                                                                    verticalAlign:
                                                                      'middle',
                                                                    width:
                                                                      '12px',
                                                                    height:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  <path
                                                                    d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                                                                    fill="currentColor"
                                                                    fill-rule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                              <div className="css-175oi2r r-13awgt0">
                                                                <div
                                                                  data-key="762a7a18186a4d83a01b463d86df071a"
                                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010"
                                                                >
                                                                  <div
                                                                    dir="auto"
                                                                    className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                                  >
                                                                    <span data-key="66b8df28082045899da7ce08176bcec5">
                                                                      <span data-offset-key="66b8df28082045899da7ce08176bcec5:0">
                                                                        Rank
                                                                        3001 to
                                                                        4000 - 2
                                                                        lottery
                                                                        tickets,
                                                                        and
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                      <div className="css-175oi2r">
                                                        <div className="css-175oi2r">
                                                          <li
                                                            data-key="025d3f6a95174828889c37e76984d40d"
                                                            className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1h8ys4a r-1mdbw0j"
                                                          >
                                                            <div
                                                              data-block-content="025d3f6a95174828889c37e76984d40d"
                                                              className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2 r-6koalj r-18u37iz r-bnwqim"
                                                            >
                                                              <div
                                                                contenteditable="false"
                                                                className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1awozwy r-6koalj r-18u37iz r-1jkjb r-1kb76zh"
                                                                style={{
                                                                  height:
                                                                    '24px',
                                                                }}
                                                              >
                                                                <svg
                                                                  viewBox="0 0 8 16"
                                                                  preserveAspectRatio="xMidYMid meet"
                                                                  className="r-1nf4jbm"
                                                                  style={{
                                                                    verticalAlign:
                                                                      'middle',
                                                                    width:
                                                                      '12px',
                                                                    height:
                                                                      '12px',
                                                                  }}
                                                                >
                                                                  <path
                                                                    d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                                                                    fill="currentColor"
                                                                    fill-rule="evenodd"
                                                                  ></path>
                                                                </svg>
                                                              </div>
                                                              <div className="css-175oi2r r-13awgt0">
                                                                <div
                                                                  data-key="314ee444224148c388de768121d20e4f"
                                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010"
                                                                >
                                                                  <div
                                                                    dir="auto"
                                                                    className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                                  >
                                                                    <span data-key="469616b1e95c4071b95232ce06d26597">
                                                                      <span data-offset-key="469616b1e95c4071b95232ce06d26597:0">
                                                                        Rank
                                                                        4001 to
                                                                        5000 - 1
                                                                        lottery
                                                                        ticket.
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </div>
                                                      </div>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 88px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="9fa504fb246c4873a38d6abf252967b2"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="9fa504fb246c4873a38d6abf252967b2"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="9bc7954ca6704429b6cbc7cd52aac216">
                                                        <span data-offset-key="9bc7954ca6704429b6cbc7cd52aac216:0">
                                                          To qualify for the
                                                          leaderboard, users
                                                          must complete the KYC
                                                          process. While a
                                                          whitelist is not
                                                          mandatory for the top
                                                          500 users, a greater
                                                          number of users are
                                                          allowed to prevent
                                                          issues arising from
                                                          individuals dropping
                                                          out of the top 500 at
                                                          the last minute.
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 105px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="9820ae46680744f99cb8d254bd4f83d0"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1ygmrgt r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="9820ae46680744f99cb8d254bd4f83d0"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <h2
                                                      className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-18u37iz r-obd0qt"
                                                      id="whitelist"
                                                    >
                                                      <div
                                                        dir="auto"
                                                        data-rnwrdesktop-gg6oyi-1x35g6-37tt59-b88u0q="true"
                                                        data-rnwr700-gg6oyi-adyw6z-135wba7-b88u0q="true"
                                                        className="css-1rynq56 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                        id="text-whitelist"
                                                      >
                                                        <span data-key="ff5fe7b7c2c049c1b3e46b347ff126ea">
                                                          <strong
                                                            data-slate-leaf="true"
                                                            data-offset-key="ff5fe7b7c2c049c1b3e46b347ff126ea:0"
                                                            className="r-crgep1 r-b88u0q"
                                                          >
                                                            Whitelist
                                                          </strong>
                                                        </span>
                                                        <a
                                                          href="#whitelist"
                                                          aria-label="Direct link to heading"
                                                          data-rnwi-1rasi3h-="true"
                                                          data-rnwi--1ghhsy9-hover-focus="true"
                                                          data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                                          data-rnwi-handle="nearest"
                                                          className="css-1qaijid r-1awozwy r-xoduu5 r-1jkjb r-orgf3d r-1loqt21"
                                                        >
                                                          <svg
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            role="presentation"
                                                            style={{
                                                              verticalAlign:
                                                                'middle',
                                                              width: '20px',
                                                              height: '20px',
                                                            }}
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.42 1.925a.6.6 0 01.405.745L7.167 4.9h3.998l.76-2.57a.6.6 0 111.15.34l-.659 2.23H14a.6.6 0 010 1.2h-1.938l-1.123 3.8H13a.6.6 0 010 1.2h-2.415l-.76 2.57a.6.6 0 01-1.15-.34l.658-2.23H5.335l-.76 2.57a.6.6 0 11-1.15-.34l.658-2.23H2a.6.6 0 110-1.2h2.438l1.123-3.8H3a.6.6 0 010-1.2h2.915l.76-2.57a.6.6 0 01.745-.405zM6.812 6.1L5.689 9.9h3.999l1.123-3.8H6.812z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 88px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="c66447ee7d0b40f2ba8a58238cef156c"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="c66447ee7d0b40f2ba8a58238cef156c"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="bf7fd5c4cca046d88fcf557aa4c3b2ad">
                                                        <span data-offset-key="bf7fd5c4cca046d88fcf557aa4c3b2ad:0">
                                                          Users within the top
                                                          500 of the leaderboard
                                                          are not required to
                                                          join the whitelist.
                                                          However, if you are
                                                          competing for a
                                                          position near the top
                                                          500, it is advisable
                                                          to err on the side of
                                                          caution and join the
                                                          whitelist!
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 105px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="e1527e04e37a415795f4dab7939b710c"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-1ygmrgt r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="e1527e04e37a415795f4dab7939b710c"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <h2
                                                      className="r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-18u37iz r-obd0qt"
                                                      id="conclusion"
                                                    >
                                                      <div
                                                        dir="auto"
                                                        data-rnwrdesktop-gg6oyi-1x35g6-37tt59-b88u0q="true"
                                                        data-rnwr700-gg6oyi-adyw6z-135wba7-b88u0q="true"
                                                        className="css-1rynq56 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                        id="text-conclusion"
                                                      >
                                                        <span data-key="3d5563f807bf4554be414548efb15841">
                                                          <strong
                                                            data-slate-leaf="true"
                                                            data-offset-key="3d5563f807bf4554be414548efb15841:0"
                                                            className="r-crgep1 r-b88u0q"
                                                          >
                                                            Conclusion
                                                          </strong>
                                                        </span>
                                                        <a
                                                          href="#conclusion"
                                                          aria-label="Direct link to heading"
                                                          data-rnwi-1rasi3h-="true"
                                                          data-rnwi--1ghhsy9-hover-focus="true"
                                                          data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                                          data-rnwi-handle="nearest"
                                                          className="css-1qaijid r-1awozwy r-xoduu5 r-1jkjb r-orgf3d r-1loqt21"
                                                        >
                                                          <svg
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            role="presentation"
                                                            style={{
                                                              verticalAlign:
                                                                'middle',
                                                              width: '20px',
                                                              height: '20px',
                                                            }}
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              clip-rule="evenodd"
                                                              d="M7.42 1.925a.6.6 0 01.405.745L7.167 4.9h3.998l.76-2.57a.6.6 0 111.15.34l-.659 2.23H14a.6.6 0 010 1.2h-1.938l-1.123 3.8H13a.6.6 0 010 1.2h-2.415l-.76 2.57a.6.6 0 01-1.15-.34l.658-2.23H5.335l-.76 2.57a.6.6 0 11-1.15-.34l.658-2.23H2a.6.6 0 110-1.2h2.438l1.123-3.8H3a.6.6 0 010-1.2h2.915l.76-2.57a.6.6 0 01.745-.405zM6.812 6.1L5.689 9.9h3.999l1.123-3.8H6.812z"
                                                              fill="currentColor"
                                                            ></path>
                                                          </svg>
                                                        </a>
                                                      </div>
                                                    </h2>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          contentVisibility: 'auto',
                                          containIntrinsicSize: '0 112px',
                                        }}
                                      >
                                        <div
                                          data-rnwrdesktop-1q6rxnj="true"
                                          data-rnwr1490-1777fci="true"
                                          data-rnwr700-i023vh-1777fci="true"
                                          className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-18u37iz r-1777fci"
                                        >
                                          <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                            <div
                                              data-rnwrdesktop-13lvk87="true"
                                              data-rnwr700-1ow6zhx-="true"
                                              className="css-175oi2r"
                                            >
                                              <div className="css-175oi2r">
                                                <div
                                                  data-key="9de9fe8630f34eca96973e47a729e8e4"
                                                  className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1yzf0co r-tskmnb r-xd6kpl"
                                                >
                                                  <div
                                                    data-block-content="9de9fe8630f34eca96973e47a729e8e4"
                                                    className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-eqz5dr r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-bnwqim r-417010 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                                  >
                                                    <div
                                                      dir="auto"
                                                      className="css-1rynq56 r-gg6oyi r-ubezar r-16dba41 r-135wba7 r-1nf4jbm r-fdjqy7 r-1xnzce8"
                                                    >
                                                      <span data-key="5fc3cd9d15d04f808a5b9d6c499871d9">
                                                        <span data-offset-key="5fc3cd9d15d04f808a5b9d6c499871d9:0">
                                                          AIPad&#x27;s
                                                          leaderboard offers a
                                                          cutting-edge solution
                                                          to motivate users to
                                                          retain its native
                                                          tokens. By
                                                          compensating users for
                                                          both their holding and
                                                          other metrics, AIPad
                                                          has developed a more
                                                          interactive and
                                                          satisfying experience
                                                          for its user base.
                                                          With its distinctive
                                                          approach to token
                                                          economics, AIPad is
                                                          well-positioned to
                                                          make a significant
                                                          impact in the
                                                          blockchain sector.
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    data-rnwrdesktop-1q6rxnj="true"
                                    data-rnwr1490-1777fci="true"
                                    data-rnwr700-i023vh="true"
                                    className="css-175oi2r r-11c0sde r-18u37iz r-1777fci"
                                  >
                                    <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-ecifi">
                                      <div
                                        data-rnwrdesktop-13lvk87="true"
                                        data-rnwr700-1ow6zhx="true"
                                        className="css-175oi2r"
                                      >
                                        <div
                                          data-rnwrdesktop-18u37iz="true"
                                          data-rnwr970-eqz5dr="true"
                                          className="css-175oi2r r-1peese0"
                                        >
                                          <a
                                            href="/aipad/"
                                            data-rnwrdesktop-1kb76zh="true"
                                            data-rnwr970-61z16t-5oul0u="true"
                                            data-rnwi-u529wo-aq1qub-c1zw6o-1khlhp8-1cut0bx-na6qhi--hover="true"
                                            data-rnwi--1tgwseu--focus="true"
                                            data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                            data-rnwi-handle="BaseCard"
                                            className="css-175oi2r r-lrvibr r-1loqt21 r-1otgn73 r-190qawg r-z2wwpe r-rs99b7 r-1udh08x r-1clhhh9 r-18u37iz r-1awozwy r-nsbfu8 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                            style={{
                                              backgroundColor:
                                                'rgba(255,255,255,1.00)',
                                              transitionDuration: '0.15s',
                                            }}
                                          >
                                            <div className="css-175oi2r r-88pszg">
                                              <svg
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid meet"
                                                data-rnwibasecard--1ghhsy9-hover="true"
                                                data-rnwi-handle="nearest"
                                                className="r-1rasi3h"
                                                style={{
                                                  verticalAlign: 'middle',
                                                  width: '24px',
                                                  height: '24px',
                                                }}
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M6.924 3.576a.6.6 0 010 .848L3.95 7.4H13.5a.6.6 0 110 1.2H3.949l2.975 2.976a.6.6 0 01-.848.848l-4-4a.6.6 0 010-.848l4-4a.6.6 0 01.848 0z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </div>
                                            <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2 r-obd0qt">
                                              <div className="css-175oi2r">
                                                <div
                                                  dir="auto"
                                                  className="css-1rynq56 r-gg6oyi r-1enofrn r-16dba41 r-14yzgew r-1rasi3h"
                                                >
                                                  Previous
                                                </div>
                                              </div>
                                              <div
                                                dir="auto"
                                                data-rnwibasecard--1ghhsy9-hover-focus="true"
                                                data-rnwi-handle="nearest"
                                                className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-ubezar r-135wba7 r-majxgm r-1nf4jbm"
                                              >
                                                Overview
                                              </div>
                                            </div>
                                          </a>
                                          <a
                                            href="/aipad/ido-information"
                                            data-rnwrdesktop-1jkjb="true"
                                            data-rnwr970-11wrixw-knv0ih="true"
                                            data-rnwi-u529wo-aq1qub-c1zw6o-1khlhp8-1cut0bx-na6qhi--hover="true"
                                            data-rnwi--1tgwseu--focus="true"
                                            data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                            data-rnwi-handle="BaseCard"
                                            className="css-175oi2r r-lrvibr r-1loqt21 r-1otgn73 r-190qawg r-z2wwpe r-rs99b7 r-1udh08x r-1clhhh9 r-18u37iz r-1awozwy r-nsbfu8 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                            style={{
                                              backgroundColor:
                                                'rgba(255,255,255,1.00)',
                                              transitionDuration: '0.15s',
                                            }}
                                          >
                                            <div className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2">
                                              <div className="css-175oi2r">
                                                <div
                                                  dir="auto"
                                                  className="css-1rynq56 r-gg6oyi r-1enofrn r-16dba41 r-14yzgew r-1rasi3h"
                                                >
                                                  Next
                                                </div>
                                              </div>
                                              <div
                                                dir="auto"
                                                data-rnwibasecard--1ghhsy9-hover-focus="true"
                                                data-rnwi-handle="nearest"
                                                className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-ubezar r-135wba7 r-majxgm r-1nf4jbm"
                                              >
                                                IDO Information
                                              </div>
                                            </div>
                                            <div className="css-175oi2r r-1ow6zhx">
                                              <svg
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                preserveAspectRatio="xMidYMid meet"
                                                data-rnwibasecard--1ghhsy9-hover="true"
                                                data-rnwi-handle="nearest"
                                                className="r-1rasi3h"
                                                style={{
                                                  verticalAlign: 'middle',
                                                  width: '24px',
                                                  height: '24px',
                                                }}
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M9.076 3.576a.6.6 0 01.848 0l4 4a.6.6 0 010 .848l-4 4a.6.6 0 01-.848-.848L12.052 8.6H2.5a.6.6 0 010-1.2h9.552L9.076 4.424a.6.6 0 010-.848z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                        <div className="css-175oi2r r-1ygmrgt r-1yv4afn r-5kkj8d">
                                          <div
                                            data-rnwrdesktop-18u37iz="true"
                                            data-rnwr1024-eqz5dr="true"
                                            className="css-175oi2r r-1awozwy r-1wtj0ep"
                                          >
                                            <div
                                              data-rnwr1024-1ifxtd0="true"
                                              className="css-175oi2r r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                            >
                                              <div
                                                dir="auto"
                                                className="css-1rynq56 r-1rasi3h r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb"
                                              >
                                                Last modified{' '}
                                                <span
                                                  aria-label="2023-03-10 16:17 UTC"
                                                  className="css-1qaijid"
                                                >
                                                  4d ago
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </main>
                            </div>
                            <aside
                              aria-label="Page outline"
                              data-rnwrdesktop-166pt5r="true"
                              data-rnwr1490-hidden="true"
                              data-rnwr700-hidden="true"
                              className="r-1oszu61 r-1xc7w19 r-1phboty r-1yadl64 r-deolkf r-6koalj r-1mlwlqe r-eqz5dr r-1q142lx r-crgep1 r-ifefl9 r-bcqeeo r-t60dpp r-417010 r-k200y r-1rnoaur r-gtdqiz r-pw2am6 r-1mkpi1y"
                              style={{
                                top: '104px',
                                maxHeight: 'calc(100vh - 104px)',
                              }}
                            >
                              {/* data-testid="page.outline" */}
                              <div className="css-175oi2r r-hu79xy r-1peese0">
                                <div className="css-175oi2r r-5njf8e r-wk8lta r-1mdbw0j">
                                  <div
                                    data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                    data-rnwi-handle="nearest"
                                    tabindex="0"
                                    className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1awozwy r-1guathk r-oyd9sg r-dnmrzs r-7xmw5f"
                                    style={{ transitionDuration: '0.15s' }}
                                  >
                                    <div className="css-175oi2r r-1kb76zh">
                                      <svg
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        preserveAspectRatio="xMidYMid meet"
                                        data-rnwinearest--1ghhsy9-hover-focus="true"
                                        data-rnwi-handle="nearest"
                                        className="r-1rasi3h"
                                        style={{
                                          verticalAlign: 'middle',
                                          width: '18px',
                                          height: '18px',
                                        }}
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M12.525 3.474a2.4 2.4 0 00-3.394 0L7.717 4.89a.6.6 0 01-.848-.849l1.414-1.414a3.6 3.6 0 015.091 5.091L11.96 9.131a.6.6 0 01-.849-.848l1.414-1.414a2.4 2.4 0 000-3.395zm-1.272 1.273a.6.6 0 010 .849l-5.657 5.657a.6.6 0 01-.849-.849l5.657-5.657a.6.6 0 01.849 0zM4.889 6.87a.6.6 0 010 .848L3.474 9.131a2.4 2.4 0 103.395 3.394l1.414-1.414a.6.6 0 01.848.849l-1.414 1.414a3.6 3.6 0 11-5.091-5.091L4.04 6.869a.6.6 0 01.849 0z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </div>
                                    <div
                                      dir="auto"
                                      data-rnwinearest--1ghhsy9-hover-focus="true"
                                      data-rnwi-handle="nearest"
                                      className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018 r-1ro0kt6 r-16y2uox r-1wbh5a2"
                                    >
                                      Copy link
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="css-175oi2r r-146iojx">
                                <div className="css-175oi2r r-18u37iz r-1awozwy r-1guathk r-5oul0u">
                                  <div
                                    dir="auto"
                                    className="css-1rynq56 r-gg6oyi r-1enofrn r-19r33im r-14yzgew r-tsynxw r-1rasi3h r-b88u0q"
                                  >
                                    On this page
                                  </div>
                                </div>
                                <div
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="nearest"
                                  tabindex="0"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1guathk r-855088 r-ms8t9i r-15zivkp r-lqms97 r-14gqq1x r-dnmrzs r-7xmw5f"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <div
                                    dir="auto"
                                    data-rnwinearest--1ghhsy9-hover="true"
                                    data-rnwi-handle="nearest"
                                    className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                  >
                                    Leaderboard Introduction
                                  </div>
                                </div>
                                <div
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="nearest"
                                  tabindex="0"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1guathk r-855088 r-ms8t9i r-15zivkp r-lqms97 r-14gqq1x r-dnmrzs r-7xmw5f"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <div
                                    dir="auto"
                                    data-rnwinearest--1ghhsy9-hover="true"
                                    data-rnwi-handle="nearest"
                                    className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                  >
                                    The Leaderboard
                                  </div>
                                </div>
                                <div
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="nearest"
                                  tabindex="0"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1guathk r-855088 r-ms8t9i r-15zivkp r-lqms97 r-14gqq1x r-dnmrzs r-7xmw5f"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <div
                                    dir="auto"
                                    data-rnwinearest--1ghhsy9-hover="true"
                                    data-rnwi-handle="nearest"
                                    className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                  >
                                    Whitelist
                                  </div>
                                </div>
                                <div
                                  data-rnwi-5xr8s6-dse9kg-2fw26j-15n4387-focus-visible="true"
                                  data-rnwi-handle="nearest"
                                  tabindex="0"
                                  className="css-175oi2r r-1i6wzkk r-lrvibr r-1loqt21 r-1otgn73 r-18u37iz r-1guathk r-855088 r-ms8t9i r-15zivkp r-lqms97 r-14gqq1x r-dnmrzs r-7xmw5f"
                                  style={{ transitionDuration: '0.15s' }}
                                >
                                  <div
                                    dir="auto"
                                    data-rnwinearest--1ghhsy9-hover="true"
                                    data-rnwi-handle="nearest"
                                    className="css-1rynq56 r-dnmrzs r-1udh08x r-1udbk01 r-3s2u2q r-1iln25a r-gg6oyi r-1b43r93 r-16dba41 r-hbpseb r-1bnj018"
                                  >
                                    Conclusion
                                  </div>
                                </div>
                              </div>
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Scroll>
      </ScrollControls>
    </>
  )
}

export default aipad
