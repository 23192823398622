import './App.css'
import './Responsive.css'
import {
  ScrollControls,
  Scroll,
  Environment,
  Float,
  Sparkles,
  PositionalAudio,
  OrbitControls,
  Stars,
  PerspectiveCamera,
  Image,
} from '@react-three/drei'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { Suspense, useMemo, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Drone } from '../../models/Drone.jsx'
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  Vignette,
} from '@react-three/postprocessing'
import sound_1 from '../../sounds/aggressive-huge-hit-logo-139134.mp3'
import sound_2 from '../../sounds/cinematic-intro-6097.mp3'
import sound_3 from '../../sounds/sunrise-114326.mp3'

function homepage() {
  return (
    <>
      <OrbitControls enableZoom={false} />
      <color attach="background" args={['black']} />
      <ambientLight intensity={2} />
      <spotLight
        position={[0, 25, 0]}
        angle={1.3}
        penumbra={1}
        castShadow
        intensity={2}
        shadow-blas={-0.0001}
      />
      <Environment preset="warehouse" />
      {/* post processing */}
      <EffectComposer>
        <Bloom
          intensity={2}
          luminanceThreshold={0.2}
          luminanceSmoothing={0.9}
          height={1000}
        />
        <DepthOfField
          focusDistance={0}
          focalLength={0.02}
          bokehScale={5}
          height={480}
        />
        <Vignette eskil={false} offset={0.1} darkness={1.6} />
      </EffectComposer>

      <ScrollControls pages={1} damping={0.25} style={{ overflow: 'hidden' }}>
        <Scroll>
          {/* first */}
          {/* <Image url={telegram}  style={{marginLeft:'10px'}}  />
           */}

          <Float
            speed={1} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <Drone scale={2.05} position={[-10, -3, -6]} />
          </Float>

          {/* second */}
          <Float
            speed={1} // Animation speed, defaults to 1
            rotationIntensity={1} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <Drone scale={2.05} position={[-1, -12.5, 0]} />
            <Drone scale={2.05} position={[12, -14.5, -10]} />
          </Float>

          {/* third */}
          <Float
            speed={1} // Animation speed, defaults to 1
            rotationIntensity={2} // XYZ rotation intensity, defaults to 1
            floatIntensity={0.2} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
          >
            <Drone scale={2.1} position={[-3, -19.5, 2]} />
            <Drone scale={2.1} position={[8, -23, -10]} />
            <Drone scale={2.1} position={[4, -24, 2]} />
          </Float>

          {/* sparkles */}

          <Sparkles
            count={500}
            speed={0.01}
            opacity={10}
            color={'FFD2BE'}
            size={0.6}
            scale={[20, 100, 20]}
            noise={0}
          ></Sparkles>
          <Sparkles
            count={50}
            speed={0.01}
            opacity={10}
            color={'FFF'}
            size={10}
            scale={[30, 100, 10]}
            noise={0}
          ></Sparkles>

          {/* sound */}
          <group position={[0, 0, 0]}>
            <PositionalAudio autoplay loop url={sound_1} distance={1.2} />
          </group>
          <group position={[0, -20, 0]}>
            <PositionalAudio autoplay loop url={sound_2} distance={3} />
          </group>
          <group position={[0, -40, 0]}>
            <PositionalAudio autoplay loop url={sound_3} distance={1.2} />
          </group>
        </Scroll>

        <Scroll html >
          <Container style={{ position: 'relative' }}>
            {/* row1 */}
            <div>
              <h1
                className="disable-text-selection heading"
                style={{
                  position: 'fixed',
                  bottom: '68%',
                  marginLeft: '10px',
                }}
              >
                LIVE THE AI ERA WITH AIOXUS
              </h1>
              <br />

              <p
                className="disable-text-selection heading-content"
                style={{
                  position: 'fixed',
                  bottom: '48%',
                  marginLeft: '10px',
                  fontSize: '15px',
                  textColor: '#fffff',
                }}
              >
                The OXUS platform aims to democratize AI by making it 
                <br />
                accessible to everyone and reducing the barriers to 
                <br />
                entry for AI development. By leveraging blockchain 
                <br />
                technology, OXUS provides a secure and decentralized 
                <br />
                platform for AI development and deployment.
              </p>
            </div>

            {/* row2 */}
            <Row
              className="text-center align-items-center justify-content-center "
              style={{
                position: 'relative',
                width: '100vw',
                height: '100vh',
                padding: '0px 30px',
                top: '250px',
              }}
            >
              {/* <Col xs={1}>
                <div>
                  <Button className="btn" href="./aibot">
                    Connect
                  </Button>
                </div>
              </Col> */}
            </Row>
          </Container>
        </Scroll>
      </ScrollControls>
    </>
  )
}

export default homepage
