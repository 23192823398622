import React from "react" 
import Homepage from './component/Homepage/homepage';
import AiBot from './component/aipad/aipad'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <>
   <BrowserRouter>
    <Routes>
    <Route path="/" element={<Homepage />} />
    <Route path="/aibot" element={<AiBot />} />
    </Routes>
   </BrowserRouter>
    </>
  )
}

export default App
