import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import logo from './logo.svg'
import { Canvas } from '@react-three/fiber'
import telegram from '../src/Images/telegram.png'
import twitter from './Images/twitter.png'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <header style={{ backgroundColor: 'black' }}>
      <img className="logo header img" src={logo} alt="logo" />
    </header>
    <footer>
      <a href="https://twitter.com/AiOxus">
        <img
          className="logo header twitter"
          src={twitter}
          alt="logo"
          target_blank
        />
      </a>
      <a href="https://t.me/AIoxus">
        <img
          className="logo header telegram"
          src={telegram}
          alt="logo"
          target_blank

        />
      </a>
    </footer>
    <Canvas>
      <App />
    </Canvas>
  </React.StrictMode>,
)

reportWebVitals()
